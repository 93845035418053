import useObserver from "../composables/useObserver";
const vReveal = {
    mounted: (el, binding) => {
        el.style.clipPath = 'polygon(0 100%, 100% 100%, 100% 100%, 0 100%)';
        if (binding.value?.speed) el.setAttribute('data-speed', binding.value.speed)
        if (binding.value?.reverse) el.setAttribute('data-reverse', binding.value.reverse)
        const observer = useObserver(activate)
        observer.observe(el)
    }
}

function activate(entries):void {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            addEventListener('scroll', () => onScroll(entry.target as HTMLElement))
        } else {
            removeEventListener('scroll', () => onScroll(entry.target as HTMLElement))
        }
    })
}

function onScroll(el):void {
    const _offetTop = Math.round(el.getBoundingClientRect().top + document.documentElement.scrollTop)
    if (scrollY + window.innerHeight > _offetTop + el.clientHeight / 2) {
        const translateAmount = (scrollY + window.innerHeight) - (_offetTop + el.clientHeight / 2) 
        const translateAmountPercent = (translateAmount / el.clientHeight) * 100 * (el.dataset.speed ?? 1)
        if (translateAmount < el.clientHeight) {
            if (el.dataset.reverse) {
                el.style.clipPath = `polygon(0 0, 100% 0, 100% ${translateAmountPercent}%, 0 ${translateAmountPercent}%)`
            } else {
                el.style.clipPath = `polygon(0 ${100 - translateAmountPercent}%, 100% ${100 - translateAmountPercent}%, 100% 100%, 0 100%)`
            }
        } else {
            el.style.clipPath = 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'
        }
    } else {
        el.style.clipPath = 'polygon(0 100%, 100% 100%, 100% 100%, 0 100%)'
    }
}

export default vReveal
