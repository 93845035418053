// Params:
// deactivate: boolean = Enables or disables transition
// animation: string = With animation it slides from ('slide-from-top|slide-from-bottom|slide-from-right|slide-from-left|zoom')
// speed: string = Speed of the transition is seconds
// delay: number = Time in miliseconds when the transition should start
// Eg. v-translate="{deactivate: true, animation: '', speed: '0.4s', delay: 1000, zoom: '1.5'}"

import useObserver from "../composables/useObserver"

const vTranslate = {
    created: (el, binding) => init(el, binding),
    mounted: (el, binding) => {
        if (!binding.value?.deactivate) {            

            if (el.parentElement) toggleOverflowHidden(el.parentElement, 'add')

            if (binding.value?.speed) el.setAttribute('data-speed', binding.value.speed)
            if (binding.value?.delay) el.setAttribute('data-delay', binding.value.delay)
            
            const observer = useObserver(activate)
            observer.observe(el)
        }
    }
}
    
function init(el, binding):void {
    if (!binding.value?.deactivate) {
        el.oldStyles = el.getAttribute('style')
        if (binding.value?.animation == 'zoom') {
            el.style.transform = `scale(${binding.value?.zoom ?? 1.5})`
        } else if (binding.value?.animation == 'slide-from-top') {
            el.style.transform = 'translateY(-100%)'
        } else if (binding.value?.animation == 'slide-from-bottom') {
            el.style.transform = 'translateY(100%)'
        } else if (binding.value?.animation == 'slide-from-right') {
            el.style.transform = 'translateX(100%)'
        } else if (binding.value?.animation == 'slide-from-left') {
            el.style.transform = 'translateX(-100%)'
        } else {
            el.style.transform = 'translateX(0%)'
        }
        el.style.opacity = '0'
    }
}

function activate(entries, observer):void {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const el = entry.target as HTMLElement
            if (el.dataset.complete) return

            const delay:number = parseInt(el.dataset.delay ? el.dataset.delay : '0')
            const speed:string = el.dataset.speed ? el.dataset.speed : '0.5s'
            setTimeout(() => animate(el, speed, observer), delay)
        }
    })
}

function animate(el, speed, observer):void {
    el.style.transition = `transform ${speed} cubic-bezier(0, 0, 0.1, 0.93)`
    el.style.transform = 'translate(0) scale(1)'
    el.style.opacity = '1'
    el.setAttribute('data-complete', true)
    
    setTimeout(() => {
        el.style = el.oldStyles
        toggleOverflowHidden(el.parentElement, 'remove')
        observer.unobserve(el)
    }, parseFloat(speed) ? parseFloat(speed) * 1000 : 5000)
}

function toggleOverflowHidden(el:HTMLElement, action: string) {
    if (action == 'add') {
        if (el.hasAttribute('class')) {
            if (!el.classList.contains('overflow-hidden')) el.classList.add('overflow-hidden')
        } else {
            const classes = el.getAttribute('class')
            el.setAttribute('class', `${classes} overflow-hidden`)
        }
    }
    if (action == 'remove') {
        if (el.hasAttribute('class') && el.classList.contains('overflow-hidden')) el.classList.remove('overflow-hidden')
    }
}

export default vTranslate
