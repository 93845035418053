import {h, onMounted} from "vue"
import {usePage} from "@inertiajs/vue3"

import 'package-vue-component-ui/dist/package-vue-component-ui.css'
import 'vue3-carousel/dist/carousel.css'
import './styles/app.css'

export const rootApp = (app, props) => ({
    setup() {
        onMounted(() => {
            const page = usePage()
        })
    },
    render: () => h(app, props)
})

export const rootSSRApp = (App, props) => ({
    setup(props) {
        onMounted(() => {
        })
    },
    render: () => h(App, props, {default: () => props.$slots.default})
})
